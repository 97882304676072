<template>
  <div class="landing-faq-page page">
    <router-link class="back" :to="{ name: 'landing' }">
      <img src="../assets/svg/back.svg" alt="retour" width="14" height="23" />
    </router-link>

    <h2 class="faq-title" v-html="$t('landingFaq.title')"></h2>

    <div class="faq-content">
      <div v-for="({ question, content }, index) of landingFaqList" :key="question">
        <h3 v-html="question"></h3>
        <div v-html="content"></div>
        <div v-if="index < landingFaqList.length - 1" class="separator"></div>
      </div>
    </div>
    <FooterSmall />
  </div>
</template>

<script>
export default {
  name: 'LandingFAQ',

  computed: {
    landingFaqList () {
      return this.$t('landingFaq.list')
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  display: block;
  margin: 25px;

  img {
    display: inline-block;
    margin-right: 40px;
    vertical-align: middle;
  }

  h2 {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include media('>=tablet') {
      display: block;
      text-align: center;
      text-decoration: none;
      text-transform: none;
    }
  }
}

.faq-title {
  text-align: center;
}

.faq-content {
  padding: 0 20px;
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 48px;

  .separator {
    margin: 30px auto;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #fff 50%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 1px;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
    margin: 0 0 25px;
  }

  ::v-deep p {
    font-weight: normal;
    font-size: 16px;
    margin: 20px 0;
  }

  ::v-deep ul {
    padding: 0 0 0 24px;
    list-style: disc;
  }

  ::v-deep img {
    max-width: 100%;
    margin: 20px auto;
    display: block;
  }
}
</style>
