<template>
  <div v-loading="isLoading" class="contact-page page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <section class="section-header">
        <div class="container">
          <h2>Préférences de contact</h2>
          <p class="tagline">
            Gérez vos préférences de contact en
            un clic. Pour vous désabonner, il suffit
            de décocher une case.
          </p>

          <ValidationObserver @submit.prevent="submit()" class="form">
            <form>

              <div class="line">
                <!-- Opt-in -->
                <p class="resume">
                  J’accepte être contacté(e) par TotalEnergies Maroc
                  à des fins d’enquêtes ou d’information et
                  souhaite recevoir les offres commerciales :
                </p>
                <div class="form-group radio-group">
                  <p class="label">Par email</p>
                  <label class="label label-radio">
                    Oui
                    <input type="radio" name="email" :value="true" v-model="inputOptinEmail" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="label label-radio">
                    Non
                    <input type="radio" name="email" :value="false" v-model="inputOptinEmail" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group radio-group">
                  <p class="label">Par SMS</p>
                  <label class="label label-radio">
                    Oui
                    <input type="radio" name="sms" :value="true" v-model="inputOptinSms" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="label label-radio">
                    Non
                    <input type="radio" name="sms" :value="false" v-model="inputOptinSms" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <!-- Language -->
                <div class="language">
                  <p class="resume">
                    Langue pour recevoir mes communications email/SMS
                  </p>
                  <div class="form-group radio-group">
                    <label class="label label-radio">
                      Français
                      <input type="radio" name="language" value="fr" v-model="inputLanguage" class="input-radio" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="label label-radio">
                      Arabe
                      <input type="radio" name="language" value="ar" v-model="inputLanguage" class="input-radio" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

              </div>

              <!-- Submit -->
              <div class="form-validate">
                <button class="btn btn-submit">Enregistrer</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <FooterSmall class="end" />
      </section>
    </MenuDesktop>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import MenuDesktop from '@/components/MenuDesktop.vue'

export default {
  data () {
    return {
      isLoading: false,
      inputOptinEmail: this.$store.getters.isOptinEmail,
      inputOptinSms: this.$store.getters.isOptinSms,
      inputLanguage: this.$store.getters.language
    }
  },
  components: {
    ValidationObserver,
    MenuDesktop
  },
  methods: {
    submit () {
      const clubist = {
        isOptinSms: this.inputOptinSms,
        isOptinEmail: this.inputOptinEmail,
        language: this.inputLanguage
      }

      this.isLoading = true
      this.$store.dispatch('editClubist', clubist)
        .then(() => {
          this.$store.commit('addAlert', {
            title: 'Merci !',
            message: 'Vos préférences de contact ont bien été enregistrées'
          })
        })
        .catch(error => {
          this.$store.commit('addAlert', {
            title: 'Une erreur est survenue',
            message: this.$t(error.message),
            bgColor: 'red'
          })
        })
        .finally(() => { this.isLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.tagline {
  margin-bottom: 2.5em;
}
.resume {
  margin-bottom: 1.3em;
}
.container {
  max-width: 400px;
}
.form-validate {
  margin-top: 50px;
}
.radio-group {
  margin-bottom: 1em;
}
.language {
  margin-top: 3em;
}
.line {
  text-align: left;
}
@include media('>tablet') {
  .container {
    max-width: 550px;
  }
  .tagline {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .resume {
    margin-bottom: 2em;
  }
  .line {
    border: solid 2px #FFF;
    padding: 40px 30px 20px;
  }
  .radio-group {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}
.section-header {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.footer-small.end {
  margin-top: auto;
}
</style>
