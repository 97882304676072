<template>
  <div class="inscription page" v-loading="isLoading">
    <section class="section-header">
      <div class="container">
        <h2 v-html="$t(`resetPassword.title`)"></h2>
        <p class="message" v-html="$t(`resetPassword.message`)"></p>
        <ValidationObserver ref="validationObserver" v-slot="{ valid }" @submit.prevent class="form">

          <!-- Password -->
          <div class="form-group">
            <label for class="label" v-html="$t(`resetPassword.password.label`)"></label>

            <ValidationProvider
              ref="pass"
              :rules="{ min: 8, required: true, regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/ }"
              v-slot="{ errors }"
              name="mot de passe"
            >
              <InputPassword v-model="password"/>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="requirements">
              <p v-html="$t(`resetPassword.requirements.title`)"></p>
              <ul>
                <li v-for="item of $t(`resetPassword.requirements.list`)" v-html="item" :key="item"></li>
              </ul>
            </div>
          </div>

          <!-- Terms and conditions -->
          <div>
            <ValidationProvider rules="required|checked" v-slot="{ errors }" name="legals">
              <div class="checkbox-group left">
                <label class="label label-checkbox empty">
                  <input
                    id="legals"
                    class="input-checkbox"
                    type="checkbox"
                    v-model="inputLegals"
                  />
                  <span class="checkmark"></span>
                </label>

                J’ai lu et j’accepte les <button @click="$eventHub.$emit('modal-conditions')" class="btn-underline-simple-white">Conditions Générales d’Utilisation</button> du TotalEnergies Club*
                <span class="error">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div v-if="apiError" class="form-error">
            <span class="error">{{ apiError }}</span>
          </div>

          <VueRecaptcha
            v-if="recaptchaSiteKey !== 'disabled'"
            ref="recaptcha"
            :sitekey="recaptchaSiteKey"
            size="invisible"
            class="captcha"
            :loadRecaptchaScript="true"
            @verify="response => captchaResponse = response"
            @expired="resetRecaptcha"
            @render="resetRecaptcha"
            @error="resetRecaptcha"
          ></VueRecaptcha>

          <!-- Submit -->
          <div class="form-validate">
            <button @click="submit" class="btn btn-submit" :disabled="!valid || apiError || !isRecaptchaOk" :class="{disabled: !valid || apiError || !isRecaptchaOk}">Valider</button>
          </div>

          <p class="notice">
            <sup>*</sup>Champs obligatoires
          </p>

        </ValidationObserver>
      </div>
    </section>

    <!-- Legals -->
    <MentionsLegales></MentionsLegales>

    <FooterSmall class="end" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha'
import MentionsLegales from '@/components/MentionsLegales.vue'
import { config } from '@/config.js'
import InputPassword from '@/components/InputPassword'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha,
    MentionsLegales,
    InputPassword
  },
  data () {
    return {
      isLoading: false,
      password: '',
      inputType: 'password',
      cardNum: '',
      apiError: null,
      firstName: '',
      lastName: '',
      recaptchaSiteKey: config.recaptcha.sitekey,
      captchaResponse: config.recaptcha.sitekey === 'disabled' ? 'disabled' : false,
      inputLegals: false
    }
  },
  computed: {
    isRecaptchaOk () {
      return !!this.captchaResponse || (config.recaptcha.sitekey === 'disabled')
    }
  },
  watch: {
    cardNum () { this.apiError = null },
    password () { this.apiError = null }
  },
  methods: {
    toggleInputType () {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
    },
    resetRecaptcha (info) {
      if (this.recaptchaSiteKey !== 'disabled') {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    },
    submit () {
      this.isLoading = true
      this.$store.dispatch('resetPassword', {
        hash: this.$route.params.hash,
        password: this.password,
        recaptcha: this.captchaResponse
      })
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.resetRecaptcha('API load error')
          this.apiError = this.$t(error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.container {
  max-width: 480px;
  padding-bottom: 0;
}

.left {
  text-align: left;
}

h2 {
  font-size: 30px;
  margin-bottom: 2em;
}
.message {
  margin-bottom: 3em;
}
.inscription {
  .section-header {
    justify-self: center;
    align-self: center;
  }
}
.requirements {
  text-align: left;
  align-self: flex-start;
  margin: 0 0 25px;
  p {
    font-weight: bold;
    margin-bottom: 15px;
  }
  ul {
    li:before {
      content: '✓';
      margin-right: 5px;
    }
  }
}
.form-validate {
  margin-top: 10px;
  margin-bottom: 50px;
}
.notice {
  text-align: left;
}
.footer-small.end {
  margin-top: auto;
}
</style>
