<template>
  <div class="contact-page page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <div class="contact-page">
        <section class="section-header">
          <div class="container cards">
            <div class="card-1">
              <h2 v-html="$t('reclamation.card.title')"></h2>
              <p class="tagline" v-html="$t('reclamation.card.message')"></p>
              <hr class="line" />
            </div>
            <div class="card-2">
              <h2 v-html="$t('reclamation.cashback.title')"></h2>
              <p class="tagline" v-html="$t('reclamation.cashback.message')"></p>
            </div>
          </div>
        </section>
        <section class="section-contact">
          <div class="container">
            <h2>Besoin d'assistance&nbsp;?</h2>
            <ContactLinkLarge />
          </div>
        </section>
        <FooterSmall class="end" />
      </div>
    </MenuDesktop>
  </div>
</template>

<script>
import MenuDesktop from '@/components/MenuDesktop.vue'
import ContactLinkLarge from '@/components/ContactLinkLarge.vue'

export default {
  components: {
    MenuDesktop,
    ContactLinkLarge
  }
}
</script>

<style lang="scss" scoped>
.contact-page {
  display: flex;
  flex-direction: column;
}
.section-header {
  margin: auto 0;
}
.section-contact {
  background-color: #fff;
  color: $blue;
  text-align: center;
  margin-bottom: 0;
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 25px;
  }
}
hr {
  height: 1px;
  border: none;
  opacity: 0.5;
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.73) 29%,
    #ffffff 50%,
    rgba(255, 255, 255, 0.86) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-bottom: 50px;
}
.cards {
  max-width: 500px;
}
.btn.desktop {
  display: none;
}
@include media('>tablet') {
  .cards {
    max-width: 850px;
    display: flex;
  }
  .card-1, .card-2 {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  .btn.desktop {
    display: block;
  }
  .card-2 {
    margin-left: 9%;
  }
  .line {
    display: none;
  }
  .tagline {
    flex-grow: 1;
  }
}
</style>
