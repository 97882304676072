<template>
  <div class="faq page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <section class="faq-page-content">
        <div v-for="{ title, list } of faqContent" :key="title">
          <h2 class="title" v-html="title"></h2>

          <div class="tablist">
            <el-collapse class="collapsible" v-model="activeName" accordion>
              <el-collapse-item v-for="(value, index) in list" :key="`faq-content-${index}`" :title="value.question">
                <div v-html="value.content" />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <FooterSmall />
      </section>
    </MenuDesktop>
  </div>
</template>

<script>
import Collapse from 'element-ui/lib/collapse'
import CollapseItem from 'element-ui/lib/collapse-item'

import MenuDesktop from '@/components/MenuDesktop.vue'

export default {
  name: 'FAQ',

  components: {
    MenuDesktop,
    ElCollapse: Collapse,
    ElCollapseItem: CollapseItem
  },

  data () {
    return {
      activeName: ''
    }
  },

  computed: {
    faqContent () {
      return this.$t('faq.content')
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {
  background-color: #df0c36;
  background-image: url('../assets/images/global-bg.png');
}

.title {
  padding: 0 32px;
  text-align: center;
  font-size: 24px;
  margin: 50px 0 40px;
}

.tablist {
  max-width: 768px;
  margin: 0 auto;
}

.collapsible::v-deep {
  padding: 0 32px;
  outline: none;

  &:focus {
    outline: none;
  }

  .el-collapse-item {
    position: relative;
    margin: 32px 0;
    outline: none;

    div[role=tab] {
      cursor: pointer;
    }

    &:before, &:last-child:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: -14px;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #fff 50%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.3;
    }

    &:last-child:after {
      top: auto;
      bottom: -18px;
    }

    p {
      margin: 16px 0;
      font-weight: 300;
    }

    ul {
      padding: 0 0 0 24px;
      list-style: disc;
    }

    img {
      max-width: 100%;
      margin: 20px auto;
      display: block;
    }
  }

  .el-collapse-item__header {
    padding-right: 32px;
    font-size: 18px;
    font-weight: 600;
    outline: none;

    &.is-active:after {
      top: 4px;
      transform: rotate(90deg);
    }

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      background-image: url('../assets/svg/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.footer-small {
  margin-top: 90px;
}
.faq-page-content {
  margin: 0;
}
</style>
