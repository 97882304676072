<template>
  <div v-loading="isLoading" class="cartes-page page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <div class="cartes-page">

        <section class="section-header">
          <div class="container">
            <h2>Vos cartes Club et TotalEnergies Card prépayée</h2>

            <ValidationObserver v-slot="{ valid }" class="form" @submit.prevent>
              <form>
                <div class="form-row">

                  <!-- Club card num -->
                  <div class="form-group">
                    <label for class="label disabled">N° carte Club</label>
                    <input type="text" class="input-text disabled" v-model="cardNum" />
                  </div>

                  <div class="form-group">

                    <!-- Prepaid card num -->
                    <template v-if="true">
                      <label for class="label disabled">N° de TotalEnergies Card prépayée</label>
                      <input type="text" class="input-text disabled" v-model="prepaidCardNumInput" />
                    </template>
                    <template v-else>
                      <label for class="label" :class="{ disabled: !canChangePrepaidCard }">N° de TotalEnergies Card prépayée</label>
                      <ValidationProvider rules="required|numeric|min:1|max:8" v-slot="{ errors }" name="TotalEnergies Card prépayée">
                        <input type="text" class="input-text" maxlength="8" :class="{ disabled: !canChangePrepaidCard }" v-model="prepaidCardNumInput" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>

                    <!-- Prepaid card claim -->
                    <template v-if="prepaidCardNum === null">
                      <p v-if="isPrepaidCardClaimOnDelivery">
                        Votre demande d'envoi de TotalEnergies Card prépayée est en cours de traitement
                      </p>
                      <p v-else-if="hasAlreadyClaimPrepaidCard">
                        Une demande de TotalEnergies Card prépayée a déjà été effectuée
                      </p>
                      <p v-else-if="dirhamsToUnlock < 50" class="soon-claim-card">
                        Vous pourrez commander une TotalEnergies Card prépayée dès que vous aurez cumulé 50 DHS
                      </p>
                      <button v-else-if="hasAlreadyClaimFirstPrepaidCard" @click="$eventHub.$emit('modal-no-secondary-prepaid-card')" class="btn btn-underline prepaid-claim-link">
                        Pas encore de TotalEnergies Card prépayée ?
                      </button>
                      <button v-else-if="statusID === 0" @click="$eventHub.$emit('modal-prepaid')" class="btn btn-underline prepaid-claim-link">
                        Pas encore de TotalEnergies Card prépayée ?
                      </button>
                    </template>
                  </div>
                </div>

                <!-- Submit -->
                <div v-if="false" class="form-validate" :class="{ disabled: prepaidCardNum !== null }">
                  <button
                    type="submit"
                    class="btn btn-submit"
                    :disabled="!valid"
                    :class="{disabled: !valid}"
                    @click="submit"
                  >Enregistrer</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>

        <section class="section-contact">
          <div class="container">
            <h2>Carte perdue ou volée?</h2>
            <ContactLinkLarge />
          </div>
        </section>

        <FooterSmall />
      </div>
    </MenuDesktop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MenuDesktop from '@/components/MenuDesktop.vue'
import ContactLinkLarge from '@/components/ContactLinkLarge.vue'

export default {
  data () {
    return {
      isLoading: false,
      prepaidCardNumInput: this.$store.getters.prepaidCardNum
    }
  },
  computed: {
    ...mapGetters([
      'cardNum',
      'prepaidCardNum',
      'dirhamsToUnlock',
      'hasAlreadyClaimPrepaidCard',
      'hasAlreadyClaimFirstPrepaidCard',
      'isPrepaidCardClaimOnDelivery',
      'statusID'
    ]),
    canChangePrepaidCard () {
      return !this.isPrepaidCardClaimOnDelivery &&
        !this.prepaidCardNum
    }
  },
  created () {
    this.prepaidCardNumInput = this.prepaidCardNum
    this.$eventHub.$on('prepaid-card-confirmed', this.confirm)
  },
  destroyed () {
    this.$eventHub.$off('prepaid-card-confirmed', this.confirm)
  },
  components: {
    MenuDesktop,
    ValidationObserver,
    ValidationProvider,
    ContactLinkLarge
  },
  methods: {
    submit () {
      if (this.prepaidCardNumInput !== null && this.prepaidCardNum === null) {
        this.$eventHub.$emit('modal-prepaid-card-confirm', { num: this.prepaidCardNumInput })
      }
    },
    confirm () {
      const clubist = {
        prePaidCardId: this.prepaidCardNumInput
      }

      this.isLoading = true
      this.$store.dispatch('editClubist', clubist)
        .then(() => {
          this.$store.commit('addAlert', {
            title: 'Merci !',
            message: 'Nous avons bien mis à jour votre TotalEnergies Card prépayée nécessaire au déblocage de votre cashback.'
          })
        })
        .catch(error => {
          this.$store.commit('addAlert', {
            title: 'Une erreur est survenue',
            message: this.$t(error.message),
            bgColor: 'red'
          })
        })
        .finally(() => { this.isLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.cartes-page {
  display: flex;
  flex-direction: column;
}
.section-header {
  margin: auto 0;
}
.section-contact {
  background-color: #fff;
  color: $blue;
  text-align: center;
  margin-bottom: 0;
  h2 {
    margin-bottom: 20px;
  }
}
.form-validate.disabled {
  display: none;
}
.error.disabled {
  display: none;
}
.prepaid-claim-link {
  color: #FFF;
  text-transform: none;
  width: none;
  font-weight: 300;
  margin-top: 1em;
}
.soon-claim-card {
  font-size: 14px;
}
</style>
