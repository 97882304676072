<template>
  <div v-loading="isLoading" class="profile-page page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <section class="section-header">
        <div class="container">
          <h2>Informations personnelles</h2>

          <ValidationObserver v-slot="{ valid }" @submit.prevent class="form">
            <form>

              <!-- Civility -->
              <div class="form-group radio-group">
                <label class="label label-radio disabled">
                  Mme
                  <input
                    type="radio"
                    checked="checked"
                    class="input-radio"
                    disabled
                    value="female"
                    v-model="civility"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="label label-radio disabled">
                  M.
                  <input type="radio" value="male" class="input-radio" disabled v-model="civility"/>
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="form-row">
                <div class="form-group">
                  <label for class="label disabled">Nom*</label>
                  <input type="text" class="input-text disabled" v-model="lastName" disabled />
                </div>

                <!-- First name -->
                <div class="form-group">
                  <label for class="label disabled">Prénom*</label>
                  <input type="text" class="input-text disabled" v-model="firstName" disabled />
                </div>
              </div>

              <div class="form-row">

                <!-- Phone -->
                <div class="form-group">
                  <label for class="label disabled">N° de téléphone*</label>
                  <input type="text" class="input-text disabled" v-model="phone" disabled />
                </div>

                <!-- Email -->
                <div class="form-group">
                  <label for class="label">Email</label>
                  <ValidationProvider rules="email|noArabicChars" v-slot="{ errors }" name="email">
                    <input type="text" class="input-text" v-model="emailInput" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-row">

                <!-- Birthdate -->
                <div class="form-group">
                  <label for class="label">Date de naissance</label>
                  <ValidationProvider rules="min:10|date|ageMin:18|ageMax:130" v-slot="{ errors }" name="date de naissance">
                    <TheMask
                      type="tel"
                      class="input-text date-fix"
                      v-model="birthDateInput"
                      placeholder="jj/mm/aaaa"
                      mask="##/##/####"
                      :masked="true"/>
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- City -->
                <div class="form-group">
                  <label for class="label">Ville</label>
                  <ValidationProvider :rules="{ regex: /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/ }" v-slot="{ errors }" name="Ville">
                    <input type="text" class="input-text" v-model="cityInput" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <!-- Password -->
              <div class="form-validate">
                <button @click="$eventHub.$emit('modal-edit-password')" class="btn btn-submit">
                  Modifier le mot de passe
                </button>
              </div>

              <!-- Submit -->
              <div class="form-validate">
                <button
                  class="btn btn-submit"
                  :disabled="!valid"
                  :class="{disabled: !valid}"
                  @click="submit()"
                >Enregistrer</button>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <FooterSmall class="end" />
      </section>
    </MenuDesktop>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { TheMask } from 'vue-the-mask'
import MenuDesktop from '@/components/MenuDesktop.vue'
import { frDateToStdDate, stdDateToFrDate } from '@/pure/dates.js'

export default {
  data () {
    return {
      isLoading: false,
      emailInput: this.$store.getters.email,
      birthDateInput: stdDateToFrDate(this.$store.getters.birthDate),
      cityInput: this.$store.getters.city,
      isOptinSmsInput: this.$store.getters.isOptinSms,
      isOptinEmailInput: this.$store.getters.isOptinEmail
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    MenuDesktop,
    TheMask
  },
  computed: {
    ...mapGetters([
      'firstName',
      'lastName',
      'civility',
      'phone',
      'email',
      'birthDate',
      'city',
      'isTaxi',
      'isOptinSms',
      'isOptinEmail'
    ])
  },
  methods: {
    submit () {
      const clubist = { }
      if (this.emailInput !== this.$store.getters.email) {
        clubist.email = this.emailInput
      }
      if (frDateToStdDate(this.birthDateInput) !== this.$store.getters.birthDate) {
        clubist.birthDate = frDateToStdDate(this.birthDateInput)
      }
      if (this.cityInput !== this.$store.getters.city) {
        clubist.city = this.cityInput
      }
      if (this.isOptinSmsInput !== this.$store.getters.isOptinSms) {
        clubist.isOptinSms = this.isOptinSmsInput
      }
      if (this.isOptinEmailInput !== this.$store.getters.isOptinEmail) {
        clubist.isOptinEmail = this.isOptinEmailInput
      }

      this.isLoading = true
      this.$store.dispatch('editClubist', clubist)
        .then(() => {
          this.$store.commit('addAlert', {
            title: 'Merci !',
            message: 'Vos informations personnelles ont bien été enregistrées'
          })
        })
        .catch(error => {
          this.$store.commit('addAlert', {
            title: 'Une erreur est survenue',
            message: this.$t(error.message),
            bgColor: 'red'
          })
        })
        .finally(() => { this.isLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.footer-small.end {
  margin-top: auto;
}
</style>
